import * as Yup from "yup";

export const SupportFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  emailAddress: Yup.string(),
  areaRelated: Yup.string().required("Required"),
  issueType: Yup.string().required("Required"),
  issueDescription: Yup.string().required("Required")
});

const SupportFormSchemaEn = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  emailAddress: Yup.string(),
  areaRelated: Yup.string().required("Required"),
  issueType: Yup.string().required("Required"),
  issueDescription: Yup.string().required("Required")
});

const SupportFormSchemaIt = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  companyName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  emailAddress: Yup.string(),
  valueAddedTax: Yup.string().required("Required"),
  issueType: Yup.string().required("Required"),
  issueDescription: Yup.string().required("Required")
});

// Export schema based on the locale condition
export const getSchema = (locale: string) => {
  let schema: any = SupportFormSchemaEn;
  if (locale === "it") {
    schema = SupportFormSchemaIt;
  }
  return schema;
};

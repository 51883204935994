import React, { ReactNode } from "react";
import { useTheme } from "styled-components";

import { Heading } from "@source-web/heading";
import { Paragraph } from "@source-web/paragraph";
import { Tab, Tabs } from "@source-web/tabs";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Block, Inline } from "@contentful/rich-text-types";

import { SupportFormContainer } from ".";
import { supportFAQLinkClick } from "../../../../lib";
import { sendSupportEmail } from "../../../../lib/services/supportFormService";
import { GetContentfulSupportForm } from "../../../manage/queries/queries.types";
import {
  HeadingWrapper,
  SupportFormTabWrapper,
  SupportPageWrapper
} from "../../styles/support.styles";
import { SupportFormValues } from "./SupportForm";

export interface ConnectedSupportPageProps {
  data: GetContentfulSupportForm["contentfulSupportForm"];
  setIsSuccessful: (isSuccessful: boolean) => void;
  formattedLocale: string;
}

export function ConnectedSupportForm({
  data,
  setIsSuccessful,
  formattedLocale
}: ConnectedSupportPageProps) {
  const theme = useTheme();

  async function handleSubmit(values: SupportFormValues) {
    try {
      const {
        firstName,
        lastName,
        emailAddress,
        companyName,
        areaRelated,
        issueType,
        issueDescription,
        valueAddedTax
      } = values;
      const messageTypeValue =
        formattedLocale === "it"
          ? `Value Added Tax: ${valueAddedTax}`
          : `Area Related: ${areaRelated}`;
      const subject = `Cyberhub Support - ${firstName} ${lastName} - ${companyName}`;
      const content = `\nName: ${firstName} ${lastName}
    Company: ${companyName}
    Email: ${emailAddress}
    ${messageTypeValue}
    Issue Type: ${issueType}
    Issue Description: ${issueDescription}`;

      await sendSupportEmail(subject, content);
      setIsSuccessful(true);
    } catch (error) {
      console.error("Error sending email", error);
    }
  }

  const richTextDocument = JSON.parse(data?.subHeading.raw);
  const options = {
    renderNode: {
      paragraph: (node: Block | Inline, children: ReactNode | ReactNode[]) => {
        return <p style={{ marginBottom: "1em" }}>{children}</p>;
      },
      hyperlink: (node: Block | Inline, children: ReactNode | ReactNode[]) => {
        return (
          <a
            href={node.data.uri}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() =>
              supportFAQLinkClick({ formattedLocale: formattedLocale })
            }
          >
            {children}
          </a>
        );
      }
    }
  };

  return (
    <SupportFormTabWrapper>
      <Tabs
        {...(theme.name === "WS10Dark" && {
          appearance: "secondary",
          inverse: true
        })}
      >
        <Tab text={data?.tabName.label} id="supportForm">
          <SupportPageWrapper id="raise-incident">
            <HeadingWrapper>
              <Heading size={3} text={data?.heading} />
              <Paragraph size={2} justify="left">
                {documentToReactComponents(richTextDocument, options)}
              </Paragraph>
            </HeadingWrapper>
            <SupportFormContainer
              data={data}
              onSubmit={handleSubmit}
              formattedLocale={formattedLocale}
            />
          </SupportPageWrapper>
        </Tab>
        <Tab text="" id=""></Tab>
      </Tabs>
    </SupportFormTabWrapper>
  );
}

import { RequestCallbackValues } from "../../../components/RequestCallback/RequestCallback";
import { fetchCyberhubMiddleware } from "../serviceUtils";

export async function sendSupportEmail(subject: string, content: string) {
  try {
    const emailPayload = {
      subject: subject,
      content: content
    };

    return await fetchCyberhubMiddleware("emails/support", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(emailPayload)
    });
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

export async function sendFormToEloqua(formData: unknown) {
  try {
    const hiddenFields = {
      elqFormName: "2022Q4VFCyberHubPROD",
      elqSiteID: "1525"
    };

    const fullPayload = {
      ...hiddenFields,
      ...(formData as Record<string, unknown>)
    };

    const response = await fetch("https://s1525.t.eloqua.com/e/f2", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: new URLSearchParams(fullPayload).toString()
    });

    if (!response.ok) {
      throw new Error(`Failed to send form: ${response.statusText}`);
    }
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

import styled, { css } from "styled-components";

export const ModalContent = styled.div(
  (props) => css`
    background: ${props.theme.color.monochrome1.default};
    padding: 2em;
    border-radius: 8px;
  `
);

export const SuccessfulSubmissionContainer = styled.div(
  (props) => css`
    height: 80vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    & > span:first-of-type > span > span > svg {
      height: 324px;
      width: 324px;
    }
    @media (max-width: ${props.theme.breakpoints.md}px) {
      & > span:first-of-type > span > span > svg {
        height: 124px;
        width: 124px;
      }
    }
  `
);

export const SuccessMessage = styled.div(
  (props) => css`
    font-size: 56px;
    width: 472px;
    text-align: center;
    line-height: 64px;
    margin: 12px 0px 36px;
    @media (max-width: ${props.theme.breakpoints.md}px) {
      width: 340px;
      font-size: 36px;
      line-height: 36px;
    }
  `
);

export const GoToButtons = styled.div(
  (props) => css`
    display: flex;
    gap: 32px;
    @media (max-width: ${props.theme.breakpoints.md}px) {
      display: flex;
      flex-direction: column;
      width: 300px;
    }
  `
);

export const FieldWrapper = styled.div(
  (props) => css`
    width: 100%;
    select option[disabled] {
      display: none;
    }

    @media (max-width: ${props.theme.breakpoints.md}px) {
      width: 100%;
    }
    @media (min-width: ${props.theme.breakpoints.lg}px) {
      width: 100%;
    }
  `
);

export const TextAreaInputWrapper = styled.div(
  (props) => css`
    width: 100%;
    grid-column: 1/-1;
    margin-bottom: 1.5em;

    @media (max-width: ${props.theme.breakpoints.md}px) {
      max-width: 100%;
    }
    @media (min-width: ${props.theme.breakpoints.lg}px) {
      max-width: 100%;
    }
  `
);

export const FormWrapper = styled.div(
  (props) => css`
    display: grid;
    grid-gap: 1.5em;
    grid-template-columns: 1fr;
    place-items: center;
    margin-top: 1.25em;
    @media (min-width: ${props.theme.breakpoints.lg}px) {
      grid-template-columns: repeat(2, 1fr);
    }
    [data-component-name="TextInputWithLabel"] {
      max-width: 100%;
    }
    [data-component-name="FieldWrapper"] {
      max-width: 100%;
    }

    div[data-component-name="TextInputWithLabel"] input[aria-disabled="true"] {
      ${props.theme.name !== "WS10" &&
      css`
        color: rgb(175, 175, 175);
        transition: box-shadow 0.4s ease 0s;
        border: 1px solid rgb(153, 153, 153);
        border-radius: 6px;
        background-color: ${props.theme.color.monochrome1.default};
      `}
    }
  `
);

export const TitleWrapper = styled.div(
  () => css`
    display: flex;
    justify-content: left;
    flex-direction: column;
    margin: auto;
  `
);

export const ButtonContainer = styled.div(
  (props) => css`
    display: flex;
    justify-content: end;
    @media (max-width: ${props.theme.breakpoints.md}px) {
      max-width: fit-content;
      margin-left: auto;
    }
  `
);

import React from "react";

import { ThemedSection } from "../../../../components";
import MessagePopup from "../../../../components/MessagePopup";
import { formRenderer } from "../../../../context/forms/DynamicJourneyModal";
import { ContentfulJsonForm } from "../../../../context/forms/form.types";
import { FormWrapper } from "../../styles/support.styles";

interface FormSection {
  form: ContentfulJsonForm["internal"]["content"];
  showForm: boolean;
  setShow: (value: boolean) => void;
  setSubmitted: (value: boolean) => void;
  isSubmitted: boolean;
}

function renderVcaForm({ form }: { form: FormSection["form"] }) {
  return formRenderer(form);
}

export function FormSection({
  form,
  showForm,
  setShow,
  isSubmitted,
  setSubmitted
}: FormSection) {
  function unmountForm() {
    setSubmitted(false);
    setShow(false);
  }

  return (
    <ThemedSection fullHeight appearance="secondary">
      {showForm && !isSubmitted ? (
        <FormWrapper> {renderVcaForm({ form })}</FormWrapper>
      ) : null}

      {isSubmitted ? (
        <MessagePopup
          onClose={() => unmountForm()}
          button={[]}
          icon={""}
          type={true}
          formattedLocale={""}
        />
      ) : null}
    </ThemedSection>
  );
}

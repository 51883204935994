import React from "react";

import { SupportForm } from ".";
import { GetContentfulSupportForm } from "../../../manage/queries/queries.types";
import { SupportFormValues } from "./SupportForm";
import { ModalContent } from "./styles/SupportForm.styles";

export interface SupportFormContainerProps {
  onSubmit: (values: SupportFormValues) => void;
  data: GetContentfulSupportForm["contentfulSupportForm"];
  formattedLocale: string;
}

export function SupportFormContainer({
  onSubmit,
  data,
  formattedLocale
}: SupportFormContainerProps) {
  return (
    <ModalContent>
      <SupportForm
        onSubmit={onSubmit}
        data={data}
        formattedLocale={formattedLocale}
      />
    </ModalContent>
  );
}

import { Form, Formik } from "formik";
import React, { useState } from "react";

import { Paragraph } from "@source-web/paragraph";
import { SelectInputWithLabel } from "@source-web/select-input-with-label";
import { TextAreaInput } from "@source-web/text-area-input";
import { TextInputWithLabel } from "@source-web/text-input-with-label";

import { PrimaryButton } from "../../../../components/PrimaryButton";
import { supportFormButtomClick } from "../../../../lib";
import { GetContentfulSupportForm } from "../../../manage/queries/queries.types";
import { getSchema } from "./SupportFormSchema";
import {
  ButtonContainer,
  FieldWrapper,
  FormWrapper,
  TextAreaInputWrapper
} from "./styles/SupportForm.styles";

export interface SupportFormValues {
  valueAddedTax?: string;
  firstName: string;
  lastName: string;
  companyName: string;
  emailAddress: string;
  issueType: string;
  areaRelated?: string;
  issueDescription: string;
}

export interface SupportFormProps {
  onSubmit: (values: SupportFormValues) => void;
  data: GetContentfulSupportForm["contentfulSupportForm"];
  formattedLocale: string;
}

const getCurrentIssueOptions = (
  data: GetContentfulSupportForm["contentfulSupportForm"]
) => {
  const content =
    data?.childContentfulSupportFormSelectInputOptionsJsonNode?.internal
      ?.content;
  return content ? JSON.parse(content).issueOptions?.data : [];
};

export function SupportForm({
  onSubmit,
  data,
  formattedLocale
}: SupportFormProps) {
  const [currentIssueOptions, setCurrentIssueOptions] = useState(() =>
    getCurrentIssueOptions(data)
  );

  if (!data) return null;
  const {
    firstName,
    lastName,
    companyName,
    emailAddress,
    childContentfulSupportFormSelectInputOptionsJsonNode,
    submitButtonText,
    descriptionText,
    descriptionLabel,
    valueAddedTax
  } = data;

  const jsonData = JSON.parse(
    childContentfulSupportFormSelectInputOptionsJsonNode.internal.content
  );
  const selectAreaRelatedPlaceholder = JSON.parse(
    childContentfulSupportFormSelectInputOptionsJsonNode.internal.content
  ).placeholder.areaRelated;

  const selectIssueTypePlaceholder = JSON.parse(
    childContentfulSupportFormSelectInputOptionsJsonNode.internal.content
  ).placeholder.issueType;

  const handleAreaChange = (value: string) => {
    const selectedArea = jsonData.areaOptions?.data.find(
      (item: { value: string }) => item.value === value
    );
    const newOptionsIds = selectedArea ? selectedArea.issueTypeOptionsMap : [];
    const newOptions = jsonData.issueOptions?.data.filter(
      (option: { id: string }) => newOptionsIds.includes(option.id)
    );
    setCurrentIssueOptions(newOptions);
  };

  return (
    <Formik
      validateOnChange
      validateOnBlur
      validateOnMount
      initialValues={{
        firstName: "",
        lastName: "",
        companyName: "",
        emailAddress: "",
        issueType: "",
        ...(formattedLocale !== "it" && { areaRelated: "" }),
        issueDescription: "",
        ...(formattedLocale === "it" && { valueAddedTax: "" })
      }}
      validationSchema={getSchema(formattedLocale)}
      onSubmit={(values: SupportFormValues) => {
        onSubmit(values);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        values,
        handleBlur,
        isSubmitting,
        touched,
        errors,
        isValid
      }) => {
        const handleCustomChange = (
          e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
        ) => {
          const { name, value } = e.target;

          if (name === "areaRelated") {
            handleAreaChange(value);
          }
          handleChange(e);
        };
        return (
          <Form>
            <FormWrapper>
              <TextInputWithLabel
                fieldWrapper={{
                  label: firstName,
                  required: true,
                  showLabel: false
                }}
                textInput={{
                  placeholder: firstName,
                  id: "firstName",
                  name: "firstName",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values.firstName
                }}
              />
              <TextInputWithLabel
                fieldWrapper={{
                  label: lastName,
                  required: true,
                  showLabel: false
                }}
                textInput={{
                  placeholder: lastName,
                  id: "lastName",
                  name: "lastName",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values.lastName
                }}
              />
              <TextInputWithLabel
                state={
                  touched.companyName && errors.companyName
                    ? "error"
                    : undefined
                }
                fieldWrapper={{
                  label: companyName,
                  required: true,
                  showLabel: false
                }}
                textInput={{
                  placeholder: companyName,
                  id: "companyName",
                  name: "companyName",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values.companyName
                }}
              />
              <TextInputWithLabel
                fieldWrapper={{
                  label: emailAddress,
                  required: true,
                  showLabel: false
                }}
                textInput={{
                  placeholder: emailAddress,
                  id: "emailAddress",
                  name: "emailAddress",
                  onChange: handleChange,
                  onBlur: handleBlur,
                  value: values.emailAddress
                }}
              />
              {valueAddedTax?.display && (
                <TextInputWithLabel
                  state={
                    touched.valueAddedTax && errors.valueAddedTax
                      ? "error"
                      : undefined
                  }
                  fieldWrapper={{
                    label: valueAddedTax?.label || "",
                    required: true,
                    showLabel: false
                  }}
                  textInput={{
                    placeholder: valueAddedTax?.label,
                    id: "valueAddedTax",
                    name: "valueAddedTax",
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.valueAddedTax
                  }}
                />
              )}
              {jsonData.areaOptions.display && (
                <FieldWrapper>
                  <SelectInputWithLabel
                    state={
                      touched.areaRelated && errors.areaRelated
                        ? "error"
                        : undefined
                    }
                    fieldWrapper={{
                      label: selectAreaRelatedPlaceholder,
                      required: true,
                      showLabel: false
                    }}
                    selectInput={{
                      placeholder: {
                        text: selectAreaRelatedPlaceholder
                      },
                      id: "areaRelated",
                      name: "areaRelated",
                      onChange: handleCustomChange,
                      onBlur: handleBlur,
                      value: values.areaRelated || "",
                      options: jsonData.areaOptions?.data
                    }}
                  />
                </FieldWrapper>
              )}
              <FieldWrapper>
                <SelectInputWithLabel
                  state={
                    touched.issueType && errors.issueType ? "error" : undefined
                  }
                  fieldWrapper={{
                    label: selectIssueTypePlaceholder,
                    required: true,
                    showLabel: false
                  }}
                  selectInput={{
                    placeholder: {
                      text: selectIssueTypePlaceholder
                    },
                    id: "issueType",
                    name: "issueType",
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: values.issueType,
                    options: currentIssueOptions
                  }}
                />
              </FieldWrapper>

              <TextAreaInputWrapper>
                <Paragraph size={2} weight={1} noMargin>
                  {descriptionLabel}
                </Paragraph>

                <TextAreaInput
                  state={
                    touched.issueDescription && errors.issueDescription
                      ? "error"
                      : undefined
                  }
                  rows={7}
                  placeholder={descriptionText}
                  id="issueDescription"
                  name="issueDescription"
                  onChange={handleChange}
                  value={values.issueDescription}
                />
              </TextAreaInputWrapper>
            </FormWrapper>
            <ButtonContainer>
              <PrimaryButton
                text={submitButtonText}
                loading={isSubmitting}
                state={!isValid ? "disabled" : undefined}
                htmlAttributes={{ type: "submit" }}
                onClick={() => {
                  supportFormButtomClick({ page_locale: formattedLocale });
                  handleSubmit();
                }}
              />
            </ButtonContainer>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SupportForm;

import { graphql } from "gatsby";
import React, { useState } from "react";

import { PageHeading } from "../../components";
import { Card } from "../../components/Card";
import MessagePopup from "../../components/MessagePopup";
import { useCampaignFormContext } from "../../context/forms/useForms";
import { ConnectedSupportForm, FormSection } from "./components/SupportForm";
import { CardWrapper, MainContainer } from "./styles/support.styles";
import { SupportPageProps } from "./types";

export const query = graphql`
  query GetSupportData($locale: String) {
    contentfulSupportForm(node_locale: { eq: $locale }) {
      heading
      submitButtonText
      successMessage
      subHeading {
        raw
      }
      childContentfulSupportFormFormJsonNode {
        internal {
          content
        }
      }
      firstName
      lastName
      emailAddress
      companyName
      descriptionText
      descriptionLabel
      messageBodyModalOne
      messageIconModalOne
      messageTypeModalOne
      successfullyButton {
        href
        buttonText
        appearance
        openInNewTab
      }
      tabName {
        label
      }
      valueAddedTax {
        display
        label
      }
      childContentfulSupportFormSelectInputOptionsJsonNode {
        internal {
          content
        }
      }
    }
    contentfulPageHeading(
      pageName: { eq: "support" }
      node_locale: { eq: $locale }
    ) {
      heading
      headingSubtext
      headingBackgroundImageDark {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundImageLight {
        gatsbyImageData(height: 450, quality: 95)
      }
      headingBackgroundMobileDark {
        gatsbyImageData(height: 400, quality: 95)
      }
      headingBackgroundMobileLight {
        gatsbyImageData(height: 400, quality: 95)
      }
    }
    contentfulDashboardContainers(
      pageName: { eq: "support" }
      node_locale: { eq: $locale }
    ) {
      contentId
      heading
      description
      badgeText
      isDarkText
      link
      backgroundAppearance
      linkText
      pillAppearance
      iconName
    }
    contentfulPackageTile(
      packageTile: { eq: "support" }
      node_locale: { eq: $locale }
    ) {
      header
      packageTile
      buttonName
      widgetInfo
      link
    }
  }
`;

export default function SupportPage({
  data,
  pageContext: { formattedLocale }
}: SupportPageProps) {
  const { isSubmitted, setIsSubmitted } = useCampaignFormContext();
  const [showForm, setShowForm] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const { headingBackgroundImageDark, heading, headingSubtext } =
    data.contentfulPageHeading;

  const { showContainer } = data.contentfulDashboardContainers;

  return (
    <MainContainer>
      {isSuccessful ? (
        <MessagePopup
          message={data?.contentfulSupportForm.messageBodyModalOne}
          button={data?.contentfulSupportForm.successfullyButton}
          icon={data?.contentfulSupportForm.messageIconModalOne}
          type={data?.contentfulSupportForm.messageTypeModalOne}
          formattedLocale={formattedLocale}
          onClose={() => setIsSuccessful(false)}
        />
      ) : (
        <>
          <PageHeading
            headingText={heading}
            headingSubtext={headingSubtext}
            backgroundImgDark={headingBackgroundImageDark}
            longerLength
            formattedLocale={formattedLocale}
            havePackageWidget
            packageTileData={data.contentfulPackageTile}
          />

          <FormSection
            showForm={showForm}
            setShow={(show: boolean) => setShowForm(show)}
            form={
              data.contentfulSupportForm.childContentfulSupportFormFormJsonNode
                .internal.content
            }
            isSubmitted={isSubmitted}
            setSubmitted={setIsSubmitted}
          />

          <ConnectedSupportForm
            data={data.contentfulSupportForm}
            setIsSuccessful={setIsSuccessful}
            formattedLocale={formattedLocale}
          />
          {showContainer && (
            <CardWrapper>
              <Card
                item={data.contentfulDashboardContainers}
                formattedLocale={formattedLocale}
              />
            </CardWrapper>
          )}
        </>
      )}
    </MainContainer>
  );
}
